import React from 'react';

import Layout from '../components/Layout';
import logo from '../assets/images/cellerMinifundi.png';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

const IndexPage = () => (
  <Layout>
    <article id="menor-de-edad">
      <header>
        <img className="logo" src={logo} alt="Celler del Minifundi" />
        <p><FormattedMessage id="menorEdadText" /></p>
      </header>
    </article>
  </Layout>
);

export default injectIntl(IndexPage);
